<template>
    <div class="container1150">
        <header><strong>EV-LR雷达液位计</strong></header>
        <main>
            <p>
                EV-LR雷达液位计是一款性价比超高1非接触式测量设备，可有效的辅助监控液位变化状态，为监测单位提供准确的液位信息。该产品工作时不受温度梯度、气压、水面水汽、水中污染物及沉淀物的影响，主要适用于江河、水库、湖泊、灌渠、河道等自然水域液位监测。具有低功耗、高精度（毫米级）、高可靠性体积小等特点，设备安装调试方便且免维护，可同时检测电鸭、液位、空高、信号强度等多种参数，能够很好的满足用户对于产品高精度和高稳定性的需求
            </p>
            <div class="img1"></div>
            <h2>产品特点</h2>
            <div class="img2"></div>
            <div class="img3"></div>
            <div class="img4"></div>
            <div class="img5"></div>
        </main>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.container1150 {
    width: 1150px;
    header {
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 26px;
    }
    main {
        p {
            // height: 20px;
            font-size: 16px;
            line-height: 30px;
        }
        h2 {
            height: 60px;
            line-height: 60px;
        }
        .img1 {
            // width: 100%;
            width: 450px;
            height: 400px;
            margin: 10px auto;
            background-image: url("../../../assets/img/product/yeweiji.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img-list {
            display: flex;
            width: 100%;
            .img1 {
                // width: 100%;
                width: 550px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/5-2.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img2 {
                // width: 100%;
                width: 550px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/5-3.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
        .img2 {
            width: 1100px;
            height: 450px;
            margin: 10px auto;
            background-image: url("/img/product/10-1.jpg");
            background-repeat: no-repeat;
            background-position-y: -1%;
            background-size: 100% 101%;
        }
        .img3 {
            width: 1100px;
            height: 550px;
            margin: 10px auto;
            background-image: url("/img/product/10-2.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img4 {
            width: 1100px;
            height: 550px;
            margin: 10px auto;
            background-image: url("/img/product/10-3.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img5 {
            width: 800px;
            height: 1050px;
            margin: 10px auto;
            background-image: url("/img/product/10-4.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
    }
}
</style>